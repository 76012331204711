// Brand Colors
$color-brand-01: #a4004f;
$color-brand-02: #32a63b;
$color-brand-03: #999;

// Colors set
$green: #32a63b;
$green-dark: #2e8030;
$green-light: #2ecc40;
$green-very-light: #c7eea4;
$blue: #1976d2;
$blue-light: #90caf9;
$orange: #ff851b;
$orange-light: #ffcc80;
$orange-very-light: #fff6e4;
$yellow: #ffcb04;
$red: #a4004f;
$red-dark: #6d0e00;
$black: #3f536e;
$gray-dark: $color-brand-03;
$gray: #ccc;
$gray-light: #dfe3e9;
$gray-very-light: #efefef;
$white: #fff;
$gray-extra-light: #fafafa;
$gray-dark-700:#596882;

// Inverse Colors
$color-inverse-01: $yellow;
$color-inverse-02: $color-brand-01;

// Font Colors
$font-color-main: $black;
$font-color-link: $color-brand-01;
$font-color-headers: $black;
$font-color-error: $red-dark;
$font-color-button: $white;
$font-color-white: $white;

$font-color-03: #8392a5;
$font-color-04: #c0ccda;
$brand-family-base: 'Roboto', sans-serif !default;

// UI Colors
$color-ui-background: $gray-very-light;
$color-ui-border: $gray-light;
$color-ui-border-focus: $green;
$color-ui-gray: $gray-dark;
$color-ui-dark: $black;

$login-page-background: url('/assets/img/login-bg.jpg');

$calendar-past-day-bg: #fbfbfb;
$calendar-today-bg: $green-very-light;
$calendar-active-day-bg: $orange-very-light;

// Headers
$header-font-weight: 500;

// Navigation
$navigation-font-color: $white;
$navigation-border-bottom: false;
$navigation-submenu-border-color: $gray-light;
$navigation-color-active-link: $color-inverse-01;
$navigation-active-link-weight: false;
$navigation-arrow-background: $white;
$navigation-arrow-offset: -9px;
$navigation-background: $color-brand-01;
$navigation-font-size: 13px;
$navigation-font-weight: 400;
$navigation-submenu-border-top-width: 1px;
$navigation-submenu-offset: 60px;
$navigation-text-transform: uppercase;
$navigation-submenu-font-size: 12px;

// Height
$height-base: 38px;
$height-header: 60px;
$height-header-mobile: 55px;
$height-footer: 46px;
$height-control: 38px;

// Width
$width-sidebar: 240px;

// Border
$border-radius: 0.25rem;
$border: solid 1px $color-ui-border;
$box-shadow: 0 0 3px 1px $color-ui-border-focus;

// Margin & padding
$base-padding: 0.75rem;

// Marker
$marker-icon-size: 16px;

// Fonts
$font-path: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';
$font-family-base: $brand-family-base;
$font-family-system: -apple-system, 'Roboto', sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-size: 13px;

// Logo
@mixin logo() {
    background-image: url("/assets/img/logos/frisco.svg");
    height: 34px;
    margin-top: 4px;
};

@mixin logo-powered() {
    color: $white;
    margin-top: 1px;
}
